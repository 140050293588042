import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
const Header = () => {
  return (
    <div className="header">
        <img className="logo" src={Logo}></img>
        <ui className="header-menu">
            <li>Look 1</li>
            <li>Look 1</li>
            <li>Look 1</li>
            <li>Look 1</li>
            <li>Look 1</li>
        </ui>
    </div>
  )
}
export default Header