import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
const Hero = () => {
  return (
    <div className="hero">
        <div className='hero-l'>
            <Header></Header>

            <div className="the-best-ad">
                <div></div>
                <span>the best fathe best fathe best fathe best fathe best fa</span>
            </div>

            <div className="hero-heading1">
              <span>Shape</span>
              <span>Your</span>
              <span>Your Shape Shape ShapeShapeShape Shape Shape Shape</span>
            </div>
        </div>
        <div className='hero-r'>right side</div>
    </div>
  )
}

export default Hero
